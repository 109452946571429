<template>
  <header class="header-wrapper" :class="{ 'border-bottom': !isHomePage }">
    <div class="header container">
      <!-- <HamburgerBtn
        class="header__menu-btn"
        :is-home-page="isHomePage"
        :on-click="onOpenSidebar"
      /> -->

      <component :is="headerComponent" />
      <!-- <MainNav class="header__nav" /> -->
    </div>
  </header>
</template>

<script>
const APP_LOGO_COMPONENT = 'AppLogo';

export default {
  name: 'AppBar',
  components: {
    AppLogo: () =>
      import(
        /* webpackChunkName: "app-logo" */ '@/components/AppBar/AppLogo.vue'
      ),
    // MainNav: () =>
    //   import(
    //     /* webpackChunkName: "main-nav" */ '@/components/AppBar/MainNav.vue'
    //   ),
    // HamburgerBtn: () =>
    //   import(
    //     /* webpackChunkName: "hamburger-menu" */ '@/components/AppBar/HamburgerBtn.vue'
    //   ),
    AppBarRouteName: () =>
      import(
        /* webpackChunkName: "app-bar-route-name" */ '@/components/AppBar/AppBarRouteName.vue'
      ),
  },
  computed: {
    isHomePage() {
      return this.$route.name.toLowerCase() === 'home';
    },
    headerComponent() {
      return this.isHomePage ? APP_LOGO_COMPONENT : 'AppBarRouteName';
    },
  },
  methods: {
    onOpenSidebar() {
      // this.$store.commit('app/setIsVisibleMainSidebar', true);
    },
  },
};
</script>

<style lang="scss" scoped>
.header-wrapper {
  height: var(--app-bar-height);

  @include respond-to('lg') {
    padding-top: 55px;
    padding-bottom: 8px;
  }

  &.border-bottom {
    border-bottom: 2px solid var(--green-600);

    @include respond-to('lg') {
      border-bottom: none;
    }
  }

  .header {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include respond-to('lg') {
      justify-content: unset;
    }

    &__menu-btn {
      position: absolute;
      top: 50%;
      left: var(--container-side-padding);
      transform: translateY(-50%);
      // margin-right: 30px;

      @include respond-to('lg') {
        display: none;
      }
    }

    &__nav {
      display: none;

      @include respond-to('lg') {
        margin-left: auto;
        display: block;
      }
    }
  }
}
</style>
